<template>
    <div ref="inspectionmodal">            
        <b-modal
        id="modal-inspection"
        modal-class="inspection-modal nopad"
        cancel-variant="outline-secondary"
        ok-only 
        ok-variant="secondary" 
        ok-title="Fermer"
        centered
        size="lg"
        style="width:800px;"
        title="Inspection"
        no-footer
        @change="loadInspection"
        >
        <h6>Inspection : {{ inspection.id }}</h6>


      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <span style="float:right" class="mb-1"> 
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(inspection.status)}`"
            class="text-capitalize"
          >
            {{ inspection.status }}
          </b-badge>
          </span>
          <!-- Full Name -->
          <b-row>
            <b-col md=6>
            <b-form-group
              label="Type d'inspection"
              label-for="type"
              v-if="inspection.id"
            >
            <span>{{ inspection.type }}</span>

              <input type="hidden" name="id"   v-model="inspection.id"/>            
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Type d'inspection"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Dénomination"
              label-for="type"
            >
              <b-form-input
                id="type"
                v-model="inspection.type"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            </b-col>
            <b-col md="6">

            <b-form-group
              label="Inspecteur"
              label-for="budget"
               v-if="inspection.id"
            >
            <span>{{ inspection.inspecteur }}</span>
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="inspecteur"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Inspecteur"
              label-for="inspecteur"
            >
              <b-form-input
                id="inspecteur"
                v-model="inspection.inspecteur"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>            
            </b-col>
          </b-row>
          <b-row>
            <b-col md=6>
            <b-form-group
              label="Ouvrage(s)"
              label-for="ouvragelst"
               v-if="inspection.id"
            >
            <span>{{ inspection.ouvragelst }}</span>
            </b-form-group>


          <validation-provider
            #default="validationContext"
            name="ouvragelst"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Ouvrage(s)"
              label-for="ouvragelst"
            >
              <b-form-input
                id="ouvragelst"
                v-model="inspection.ouvragelst"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            </b-col>
            <b-col md=6>

            <b-form-group
              label="Composant(s)"
              label-for="composants"
               v-if="inspection.id"
            >
            <span>{{ inspection.composants }}</span>
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="composants"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Composant(s)"
              label-for="composants"
            >
              <b-form-input
                id="composants"
                v-model="inspection.composants"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            </b-col>
          </b-row>
  
          <!-- Email -->

          <b-row>
            <b-col md=6>          
            <b-form-group
              label="Date souhaitée"
              label-for="startdate"
              v-if="inspection.id"
            >
             <span>{{ inspection.startdate }}</span>
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="startdate"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Date souhaitée"
              label-for="startdate"
            >
              <b-form-datepicker
                id="startdate"
                v-model="inspection.startdate"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                locale="fr"
                v-bind="labels['fr'] || {}"
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>            
            </b-col>
            <b-col md="6">

          <validation-provider
            #default="validationContext"
            name="reelstart"
          >
            <b-form-group
              label="Début réel"
              label-for="reelstart"
              v-if="!noedit"              
            >
              <b-form-datepicker
                id="reelstart"
                v-model="inspection.reelstart"
                v-bind="labels['fr'] || {}"
                locale="fr"
                :state="getValidationState(validationContext)"
              />       
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>  
            </b-col>
          </b-row>


          <b-row>
            <b-col md=6>
            <b-form-group
              label="Budget prévisionnel"
              label-for="budget"
               v-if="inspection.id && !noedit"
            >
            <span>{{ inspection.budget }}</span>
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="budget"
            rules="required"
            v-if="!inspection.id"
          >
            <b-form-group
              label="Budget prévisionnel"
              label-for="budget"
              v-if="!noedit"
            >
              <b-form-input
                id="budget"
                v-model="inspection.budget"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            </b-col>
            <b-col md="6">

          <validation-provider
            #default="validationContext"
            name="reelbudget"
            rules="required"
          >
            <b-form-group
              label="Budget Réel"
              label-for="reelbudget"
              v-if="!noedit"
            >
              <b-form-input
                id="reelbudget"
                v-model="inspection.reelbudget"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>          
            </b-col>
          </b-row>
          
<!--     contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
-->

            <b-form-group
              label="Historique"
              label-for="historique"
              v-if="inspection.historique && inspection.historique.length > 0"
            >
             <span v-for='value of inspection.historique'>{{ value }}<br/></span>
            </b-form-group>             

            <b-form-group
              label="Documents"
              label-for="historique"
            >
            <span v-for='value of inspection.documents' v-if="inspection.id">
              <b-link>{{ value }}</b-link><br/>
            </span>   
            </b-form-group>

        </b-form>
      </validation-observer>
      
             <b-form-group
              label="Avancement des tâches liées"
              label-for="historique"
              v-if="inspection.taches && inspection.taches.length > 0"
            >     
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="inspection.taches"
                responsive
                :fields="tableColumnsTache"
                primary-key="id"
                show-empty
                empty-text="Pas d'historique"
              >
              </b-table>

          </b-form-group> 

        </b-modal>
    </div>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BTable,BLink, BContainer, BRow, BCol, BFormTextarea,BBadge,BImg,BFormInvalidFeedback,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useInspectionList from '@/views/apps/inspection/useInspectionList'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  directives: {
    Ripple,
  },  
  model: {
    prop: 'inspection',
    prop: 'noedit'
  },    
  props: {
    inspection: {
      type: Object,
      required: false,
    default: (() => {id:''}),
    },
    noedit: {
      type: Boolean,
      required: false,
    default: false,
    },    
  },  
  name: "inspectionmodal",
  components: {
      BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BTable,BLink,BContainer, BRow, BCol,BFormTextarea,BBadge,BImg,BFormInvalidFeedback,BFormDatepicker,
        // Form Validation
    ValidationProvider,
    ValidationObserver,
    },
  methods: {       
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },   
    loadInspection: function() {
    }
  },
  
   data() { 
       return {
           fieldsTypeouvrage: [
                { key: "famille", label: "Famille d'ouvrage", type: "text",thStyle: { width: "20%", height: "40px" }  },
                { key: "type", label: "Type d'ouvrage", type: "text",thStyle: {height: "40px" } },
            ], 
            labels: {
                fr: {
                  labelNoDateSelected: 'Pas de date sélectionnée',
                  labelHelp: ''
                },  
            },   
       }
   },
  setup(props) { 
    const {

      resolveUserStatusVariant,
    } = useInspectionList()
    
    const resetincidentData = () => {
    }    

  const tableColumnsTache = [
    { key: 'responsable',label:"Responsable", sortable: true },
    { key: 'tache',label:"Tache", sortable: true },
    { key: 'deadline',label:"Deadline", sortable: true },
    { key: 'status',label:"Statut", sortable: true },
  ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetincidentData)    

    return {
            refFormObserver,
      getValidationState,
      resetForm,
        resolveUserStatusVariant,
        tableColumnsTache
    }
  },   
  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


