{{ src/components/Gantt.vue }}
<template>
  <div ref="gantt">
  </div>
</template>
 
<script>
import {gantt} from 'dhtmlx-gantt';
export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    },
    ganttType: {
      type: String,
      default () {
        return ''
      }
    }    
  },

    data() {
       return {
           inspectionTask:{id:''},
       }     
    },
 
  watch: {
      tasks: {
          //deep:true,
        handler () { 
            gantt.clearAll()
            gantt.parse(this.tasks)

        }
    }                      
    },

 methods: {
   /* showInspection: function(id) {
        this.$http.get('/apps/inspection/inspections/'+id)
        .then(response => { 
            this.inspectionTask = response.data  
            this.$root.$emit('bv::show::modal','modal-inspection')      
            })
        .catch((e)=>{console.log(e)})  
    },*/

    createGantt: function() {
        gantt.config.xml_date = "%Y-%m-%d";
            gantt.config.min_column_width = 20;
            gantt.config.scale_height = 90;
            gantt.i18n.setLocale("fr");
            var weekScaleTemplate = function (date) {
                var dateToStr = gantt.date.date_to_str("%d %M");
                var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
            };

            var daysStyle = function(date){
                // you can use gantt.isWorkTime(date)
                // when gantt.config.work_time config is enabled
                // In this sample it's not so we just check week days

                if(date.getDay() === 0 || date.getDay() === 6){
                    return "weekend";
                }
                return "";
            };
        gantt.config.date_grid = "%d/%m/%Y";
            gantt.config.scales = [
                {unit: "month", step: 1, format: "%F, %Y"},
                {unit: "week", step: 1, format: weekScaleTemplate},
                {unit: "day", step:1, format: "%D", css:daysStyle }
            ];

        gantt.templates.task_class  = function(start, end, task){
            switch (task.status){
                case "Planifié":
                case "planifié":

                    return "medium";
                    break;
                case "en cours":
                case "En cours":
                    return "medium";
                    break;
                case "terminé":
                case "Terminé":  
                    return "ended";
                    break;
                case "retard":
                    return "high";
                    break;
                case "rapport":
                    return "low";
                    break;        
                case "a planifier":
                case "A planifier":
                    return "low";
                    break;                              
            }
        };

        gantt.attachEvent("onTaskClick", function(id,e){
            const event = new CustomEvent('showDetail',{
                bubbles: true,
                detail: { id: id, type:gantt.getTask(id).type}
                });

            document.dispatchEvent(event);

        });

        gantt.attachEvent("onTaskDblClick", function(id,e){
            //any custom logic here
            return false;
        });

        if (this.$props.ganttType == 'inspection') {
            gantt.config.columns = [
                { name: "text", label: "Inspection", width: "200" },
                { name: "start_date", label: "Date début", align: "center"},
                { name: "duration", label: "Durée", width: "100", align: "center" },
            ];

        } else {
            gantt.config.columns = [
                { name: "text", label: "Maintenance", width: "300" },
                { name: "start_date", label: "Date début", align: "center"},
                { name: "duration", label: "Durée", width: "100", align: "center" },
            ];  

        }
            gantt.init(this.$refs.gantt);
            gantt.clearAll();
            gantt.parse(this.$props.tasks);
    },
},

  mounted: function () {
  //gantt.config.xml_date = "%Y-%m-%d";
    this.createGantt()
    

    // Listen for the event.
    document.addEventListener('showDetail', (e) => {
        this.$emit("showDetail",e.detail.id)
    });
  }
}
</script>
 
<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
    .gantt_task_line{
        border-color: rgba(0, 0, 0, 0.25);
    }
    .gantt_task_line .gantt_task_progress {
        background-color: rgba(0, 0, 0, 0.25);
    }
 
    /* high */
    .gantt_task_line.high {
        background-color: #03A9F4;
    }
    .gantt_task_line.high .gantt_task_content {
        color: #fff;
    }
 
    /* medium */
    .gantt_task_line.medium {
        background-color: #f57730;
    }
    .gantt_task_line.medium .gantt_task_content {
        color: #fff;
    }
 
    /* low */
    .gantt_task_line.low {
        background-color: #e157de;
    }
    .gantt_task_line.low .gantt_task_content {
        color: #fff;
    } 
    
    /* low */
    .gantt_task_line.ended {
        background-color: #3fa33c;
    }
    .gantt_task_line.ended .gantt_task_content {
        color: #fff;
    }    

</style>