import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInspections(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/inspection/inspections', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInspection(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/inspection/inspections/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addInspection(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/inspection/inspections', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },  
    getShapes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/inspection/shapes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },  
    addShape(ctx, shape) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/inspection/shapes', { data: shape })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
  },
}
