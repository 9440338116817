<template>
  <v-select
    ref="vSelect"
    v-model="localSelected"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    multiple
    taggable
    push-tags
    deselect
    :options="assetList"
    label="name"
    @input="(viewAsset) => $emit('changeAsset', viewAsset)"
  >
    <template #option="{ name }">   
      <span> {{ name }}</span>
    </template>

    <!-- eslint-disable-next-line vue/no-unused-vars  -->
    <template #no-options="{ search, searching, loading }">
      Aucun résultat pour votre sélection
    </template>

    <template
      #selected-option-container="{ option, multiple}"
      :multiple="multiple"
    >
      <div v-if="option.type === 'worksite'" class="vs__selected" style="background-color: #2daa80;">    
          <div class="col">
          <span>{{ option.name}}
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>              
          </span>
        </div>
      </div>
      <div v-if="option.type=== 'asset'" class="vs__selected" style="background-color: #814c0f;">    
          <div class="col">
          <span>{{ option.name }}</span>
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>           
        </div>
      </div>     
      <div v-if="option.type=== 'type'" class="vs__selected" style="background-color: #0c2eee;">    
          <div class="col">
          <span>{{ option.name }}</span>
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>           
        </div>
      </div>         
      <div v-if="option.type=== 'priorite'" class="vs__selected" style="background-color: #eb2b0a;">    
          <div class="col">
          <span>{{ option.name }}</span>
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>           
        </div>
      </div>        
      <div v-if="option.type=== 'status'" class="vs__selected" style="background-color: #5cb10d;">    
          <div class="col">
          <span>{{ option.name }}</span>
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>           
        </div>
      </div>       
      <div v-if="typeof(option.type) === 'undefined'" class="vs__selected" style="background-color: #d39a58;">    
          <div class="col">
          <span>Filtre :: {{ option.name }}</span>
            <button  @click="event => deselect(option)" type="button" class="vs__deselect" :title="`Deselect ${option.name}`" :aria-label="`Deselect ${option.name}`" ref="deselectButtons">
                   <span>x</span>
            </button>           
        </div>
      </div>        
    </template>    
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import {
BImg, BIcon
} from 'bootstrap-vue'
export default {
  components: {
    vSelect,BImg, BIcon
  },
    props: {
        assetList: {
            type: Array,
            default: () => [
            ]        
        },
        selected1: {
            type: Object,
            default: () => {}
        },        
    },

  watch: {
      selected1: {
      handler () { 
        if (this.selected1 !== {})
            this.localSelected.push(this.selected1)
        this.$emit('changeAsset', this.localSelected)
      }
    },
  },
   methods: {
       deselect: function(option) {
           this.localSelected = this.localSelected.filter(a => a.id !== option.id)
            const searchEl = this.$refs.vSelect.searchEl;
            if (searchEl) {
                searchEl.blur();
            }
           this.$emit('changeAsset', this.localSelected)
       }
   }, 
  data() {
    return {
        localSelected:[]
    }
  },
}
</script>