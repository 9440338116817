<template>
    <div class="ecommerce-searchbar mt-1" style="height:500px" >

         <inspectionmodal :multi="false"  class="mt-2" :inspection="inspection"/>
    <!-- Filters -->
      <inspection-list-filters
        :asset-options="$userContext.assetList"   
        :status-options="statusOptions"  
        @changeMode="(val) => {currentView = val}"
        @changeAsset="(asset) => handleSearch(asset)"
        @addInspection="onNewInspection"
      />      
      <b-row>
        <b-col cols="12" style="height:500px" v-if="currentab === true">
       
       
        <gantt class="left-container" 
          :tasks="inspectionGanttList" 
          ganttType="inspection"
          @store="store = $event"
          v-show="currentView === 'gantt'"
          @showDetail="(inspectionId) => showInspectionFromId(Number(inspectionId))" 
          ></gantt>


     <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="inspectionList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucune inspection trouvée"
        :sort-desc.sync="isSortDirDesc"
        v-show="currentView === 'list'"
      >
        <template #empty="scope">
          <h4>Aucune inspection trouvée</h4>
        </template>
        <template #emptyfiltered="scope">
          <h4>Aucune inspection trouvée</h4>
        </template>
        <!-- Column: User -->
             <!-- Column: User -->
        <template #cell(type)="data">
            <b-link
              @click="showInspection(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.type }}
            </b-link>
        </template>

        <!-- Column: Role -->
        <template #cell(ouvrage)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.ouvragelst }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(date)="data">
          <span class="align-text-top text-capitalize">     
            {{ data.item.date }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Inspection {{ dataMeta.from }} à {{ dataMeta.to }} sur {{ totalInspections }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalInspections"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-show="currentView === 'list'"
              @input="showNewPage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>




        </b-col>
      </b-row>  
  </div>

</template>
 
<script>
import Gantt from '../../../components/Gantt.vue';
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import inspectionStoreModule from '@/views/apps/inspection/inspectionStoreModule'

import InspectionListFilters from '@/views/apps/inspection/InspectionListFilters.vue'
import Assettypes from '@/@fake-db/data/apps/assettype.js'
import useInspectionList from '@/views/apps/inspection/useInspectionList'
import inspectionmodal from '@/views/apps/inspection/InspectionModal.vue'


import {
   BRow, BCol,BTable,BLink, BBadge,BPagination,
} from 'bootstrap-vue'
 
export default {
  name: 'app',
  components: {Gantt,inspectionStoreModule,InspectionListFilters,BRow,BCol,BTable,BLink, BBadge,BPagination,inspectionmodal,},
data () {
    this.filters = {assets:[],priorite:[],type:[],queries:[], status:[]}
    this.getInspectionList()
      
    return {
      currentView: 'list',
      inspection: {id:''},
      inspectionGanttList: {
        data: [],
        links: [],
      },
      inInspectionId:-1,
      filters: {assets:[],priorite:[],type:[],queries:[], status:[]} ,
      inspectionList:[],
      currentab : false,
      totalInspections: 0
    }
  },


   methods: {
     showNewPage: function() {
       this.getInspectionList()
     },
    addInspection: function (inspection) {

      let task = {id:inspection.id, text:(inspection.type+' '+inspection.ouvragelst), start_date:inspection.start_date,duration:inspection.duration, status:inspection.status, type:'inspection'}
      this.inspectionGanttList = {data:[...this.inspectionGanttList.data,task]}
      
      this.inspectionList.push(inspection)
    },
   
    showInspectionFromId: function(id) {
      let inspection = this.inspectionList.find(a => a.id === id)
      this.showInspection(inspection)
    },

    showInspection: function(inspection) {
      this.inspection = inspection
      this.$root.$emit('bv::show::modal','modal-inspection')
    },

    onNewInspection: function() {
       this.inInspectionId = -1     
       this.isAddNewInspectionSidebarActive = true      
    },

    onInspectionEdit: function (inspectionId) { 
       this.inInspectionId = inspectionId     
       this.isAddNewInspectionSidebarActive = true

    },       

    onAddInspection: function () { 
        this.isAddNewInspectionSidebarActive = false
        this.getInspectionList()
  }, 

  handleSearch: function(val) {

      this.filters = {assets:[],priorite:[],type:[],queries:[], status:[]} 
  
     val.forEach(
       a =>{
        if (typeof(a.id) !== "undefined") {
          if (a.type === 'asset') {
              this.filters.assets.push(a.name)
          } else if (a.type === 'priorite') {
              this.filters.priorite.push(a.id)
          } else if(a.type === 'type') {
              this.filters.type.push(a.id)
          } else if(a.type === 'status') {
              this.filters.status.push(a.id)
          } 
        } else {
          this.filters.queries.push(a.name)
        }
       }
     )

       this.getInspectionList()

    }, 

  getInspectionList: function() {

      this.inspectionList = []
      this.inspectionGanttList = {
        data: [],
        links: [],
      },


      store
      .dispatch('app-inspection/fetchInspections', {
        q: this.filters,
        page: this.currentPage
      })
      .then(response => {
        const { inspections, total } = response.data
        this.totalInspections = total
        inspections.forEach(inspection => this.addInspection(inspection));
        this.currentab = true
      })
      /*.catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })*/
      .catch((e) => console.log(e))

  }



  },

  setup() {
    
    const USER_APP_STORE_MODULE_NAME = 'app-inspection'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, inspectionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

      const statusOptions = [
      { label: 'Planifié', value: 'planifié' },
      { label: 'En cours', value: 'en cours' },
      { label: 'Terminé', value: 'terminé' },
      { label: 'En retard', value: 'retard' },
      { label: 'Rapport', value: 'rapport' },
    ]

    const {
      tableColumns,
      perPage,
      currentPage,

      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
      typeFilter,
      prioriteFilter,
    } = useInspectionList()

     const isAddNewInspectionSidebarActive = ref(false)

    return { 


      tableColumns,
 
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,


      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
      typeFilter,
      prioriteFilter,            
      Assettypes,
      isAddNewInspectionSidebarActive
    }











  },
}
</script>

<style>
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .container {
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .gantt_task_cell {
    width: 35px;
  }
</style>